import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { userDetails } from '../../services';
import Logo from '../../assets/images/logo.png';

const Navbar = () => {
  const [email, setEmail] = useState("");
  const [fullname, setFullname] = useState("");
  const [profile, setProfile] = useState("");
  const [user, setUser] = useState(null);

  useEffect(() => {
    (async () => {
      const user = await userDetails();
      console.log("Fetched user:", user); // Debugging line
      if (user != null) {
        setUser(user.user);
        setEmail(user.user.email);
        setFullname(user.user.fullname);
        setProfile(user.user.profileImage);
      }
    })();
  }, []);

  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  };

  const logout = () => {
    localStorage.removeItem('user');
    window.location = "/";
  };

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo" to="/dashboard"><img src={Logo} alt="logo" /></Link>
        <Link className="navbar-brand brand-logo-mini" to="/dashboard"><img src={Logo} alt="logo" /></Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
          <span className="mdi mdi-menu"></span>
        </button>
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link">
                <div className="nav-profile-img">
                  <img src={profile || Logo} alt="user" /> {/* Fallback to Logo */}
                  <span className="availability-status online"></span>
                </div>
                <div className="nav-profile-text">
                  <p className="mb-1 text-black"><Trans>{fullname || 'User'}</Trans></p>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="navbar-dropdown">
                <Dropdown.Item onClick={(evt) => evt.preventDefault()}>
                  <Link style={{ border: 'none', color: 'black' }} to={{
                    pathname: `/profile/${user?._id}`,
                    state: { user }
                  }}>
                    <Trans>Profile</Trans>
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item onClick={(evt) => evt.preventDefault()}>
                  <Link style={{ color: 'black' }} onClick={logout} to="#">
                    Logout
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;