import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';

// import { userDetails } from '../services';
// import {Protected} from './Protected';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Users = lazy(() => import('./users/Users'));
const AddUser = lazy(() => import('./users/Adduser'));

const Payment = lazy(() => import('./payment/payment'));


const Country = lazy(() => import('./country/Country'));
const AddCountry = lazy(() => import('./country/AddCountry'));

const States = lazy(() => import('./states/States'));
const AddStates = lazy(() => import('./states/AddStates'));

const DanceClass = lazy(() => import('./danceClass/DanceClass'));
const AddDanceClass = lazy(() => import('./danceClass/AddDanceClass'));

const Category = lazy(() => import('./category/Category'));
const AddCategories = lazy(() => import('./category/AddCategories'));

const City = lazy(() => import('./city/City'));
const AddCities = lazy(() => import('./city/AddCities'));

const Banners = lazy(() => import('./banners/Banners'));
const Addbanners = lazy(() => import('./banners/Addbanners'));

const Login = lazy(() => import('./user-pages/Login'));

const Studio = lazy(() => import('./studio/Studio'));
const Studiolist = lazy(() => import('./studio/Studiolist'));
const Studiodetails = lazy(() => import('./studio/Studiodetails'));

const Hireus = lazy(() => import('./hireus/Hireus'));
const Addhireus = lazy(() => import('./hireus/Addhireus'));
const HireusDetails = lazy(() => import('./hireus/HireusDetails'));
const HireContact = lazy(() => import('./hirecontact/HireContact'));

const Price = lazy(() => import('./price/Price'));
const AddPrice = lazy(() => import('./price/AddPrice'));

const LearnVideo = lazy(() => import('./learnvideo/LearnVideo'));
const LearnVideoDetails = lazy(() => import('./learnvideo/LearnVideoDetails'));

const Punlimited = lazy(() => import('./premium/Punlimited'));
const PremiumAdd = lazy(() => import('./premium/Premiumadd'));

const Profile = lazy(() => import('./users/Profile'));
const Contacts = lazy(() => import('./contact/Contacts'));
const SongRequest = lazy(() => import('./songrequest/SongRequest'));


const AppRoutes = () => {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <Route exact path="/" component={ Login } />

          {/* <Protected isLoggedIn={isLoggedIn}> */}
            <Route path="/dashboard" component={Dashboard } />

            <Route path="/users" component={ Users } />
            <Route path="/adduser" component={ AddUser } />
            <Route path="/adduser/:id" component={ AddUser } />

            <Route path="/payment" component={ Payment } />

            <Route path="/country" component={ Country } />
            <Route path="/addcountry" component={ AddCountry } />
            <Route path="/addcountry/:id" component={ AddCountry } />

            <Route path="/states" component={ States } />
            <Route path="/addstates" component={ AddStates } />
            <Route path="/addstates/:id" component={ AddStates } />

            <Route path="/danceclass" component={ DanceClass } />
            <Route path="/adddanceclass" component={ AddDanceClass } />
            <Route path="/adddanceclass/:id" component={ AddDanceClass } />

            <Route path="/profile/:id" component={ Profile } />

            <Route path="/category" component={ Category } />
            <Route path="/addcategory" component={ AddCategories } />
            <Route path="/addcategory/:id" component={ AddCategories } />

            <Route path="/city" component={ City } />
            <Route path="/addcities" component={ AddCities } />
            <Route path="/addcities/:id" component={ AddCities } />

            <Route path="/banners" component={ Banners } />
            <Route path="/addbanner" component={ Addbanners } />
            <Route path="/addbanner/:id" component={ Addbanners } />

            <Route path="/studio" component={ Studio } />
            <Route path="/studio/:id" component={ Studio } />
            <Route path="/studiolist" component={ Studiolist } />
            <Route path="/studiodetails/:id" component={ Studiodetails } />

            <Route path="/hireus" component={ Hireus } />
            <Route path="/hirecontact" component={ HireContact } />
            <Route path="/addhireus" component={ Addhireus } />
            <Route path="/addhireus/:id" component={ Addhireus } />
            <Route path="/hireusdetails/:id" component={ HireusDetails } />

            <Route path="/price" component={ Price } />
            <Route path="/addprice" component={ AddPrice } />
            <Route path="/addprice/:id" component={ AddPrice } />

            <Route path="/learnvideo" component={ LearnVideo } />
            <Route path="/learnvideodetails" component={ LearnVideoDetails } />

            <Route path="/punlimited" component={ Punlimited } />
            <Route path="/Premiumadd" component={ PremiumAdd } />
            <Route path="/Premiumadd/:id" component={ Studiodetails } />

            <Route path="/contactlist" component={ Contacts } />

            <Route path="/songrequestlist" component={ SongRequest } />
          {/* </Protected> */}


          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );
}

export default AppRoutes;